.content {
  width: 100%;
  height: 100%;
}

.tweet, .account{
  margin : 2px 20px;
  color: #187b41;
}

.tweet a{
  text-decoration: none;
  color: black;
  font-size: small;
}

.account{
  vertical-align: middle;
}

.account a{
  text-decoration: none;
  color: #187b41;
  font-size: small;
  margin-left: 6px;

}

.green{
  color: #187b41;
}

@media screen and (max-width: 600px) {
  #titleheader{
    font-size: 2.5vw;
  }
  
  #subtitle{
    font-size: 2vw;
  }
  
  .mini{
    font-size: 1.5vw;
    white-space: nowrap;
  }
}

@media screen and (max-width: 1000px) {
  #titleheader{
    font-size: 2.5vw;
  }
  
  #subtitle{
    font-size: 1.5vw;
  }
  
  .mini{
    font-size: 1vw;
    white-space: nowrap;
  }
}
@media screen and (min-width: 1000px) {
  #titleheader{
    font-size: 2.5vw;
  }
  
  #subtitle{
    font-size: 1.2vw;
  }
  
  .mini{
    font-size: 0.8vw;
    white-space: nowrap;
  }
}

#tools > * {
  padding: 0.8vw;
  cursor: pointer;
}

.backgroundalpha{
  background-color:rgba(255,255,255,0.3);
  padding: 10px;
  left: 50%;
  transform: translateX(-50%);
  top: 50%;
  transform: translateY(-50%);
}

.header{
  margin: auto;
}

.infinite-scroll-component {
  overflow: hidden;
}

#subtitle2{
  font-size: 2.5vw;
  color: #187b41;
}

.input{
  margin-left: 5px;
  font-size: 1vw;
}

.searchBtn {
  color: #187b41;
  border: 1px solid #187b41;
  padding: 2px 5px;
  margin-left: 5px;
  font-size: 1vw;
}
.author{
  font-size: 1vw;
}